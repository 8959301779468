<template>
  <div>
    <h4>{{ name }}</h4>
    <v-form ref="form" v-model="isValid">
      <v-container>
        <v-row class="mt-3">
          <v-col cols="12" md="8">
            <h5>Furniture</h5>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="8">
            <v-chip-group
              multiple
              v-model="form.furniture"
              column
              active-class="chip-active-style"
            >
              <v-chip
                v-for="furnitureItem in furnitureItems"
                :key="furnitureItem"
                :value="furnitureItem"
                outlined
                class="chip-style"
              >
                {{ furnitureItem }}
              </v-chip>
            </v-chip-group>
          </v-col>
          <v-col cols="12" md="4">
            <v-card color="" class="px-3 py-2 default" outlined
              >What kind of furniture is in the venue?</v-card
            >
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="8">
            <v-text-field
              label="Add new furniture type"
              v-model="newFurniture"
              :rules="[getRules.string]"
              append-icon="mdi-plus"
              @keydown.enter.prevent="addFurniture"
              @click:append="addFurniture"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-card color="" class="px-3 py-2 default" outlined
              >Add new furniture type and click <strong>+</strong> icon
              button</v-card
            >
          </v-col>
        </v-row>

        <v-row class="mt-5">
          <v-col cols="12">
            <h5>What human resources do you have?</h5>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="8">
            <v-chip-group
              multiple
              active-class="chip-active-style"
              v-model="form.human_resources"
              column
            >
              <v-chip
                :value="humanResource"
                v-for="humanResource in humanResources"
                :key="humanResource"
                outlined
                class="chip-style"
              >
                {{ humanResource }}
              </v-chip>
            </v-chip-group>
          </v-col>
          <v-col cols="12" md="4">
            <v-card color="" class="px-3 py-2 default" outlined
              >What human resources are available for the venue?</v-card
            >
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="8">
            <v-text-field
              label="Add new human resource"
              v-model="newHumanResource"
              append-icon="mdi-plus"
              :rules="[getRules.string]"
              @keydown.enter.prevent="addHumanResource"
              @click:append="addHumanResource"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-card color="" class="px-3 py-2 default" outlined
              >Add new human resource and click <strong>+</strong> icon
              button</v-card
            >
          </v-col>
        </v-row>

        <v-row class="mt-5">
          <v-col cols="12" md="8">
            <h5>Catering</h5>
          </v-col>
          <v-col cols="12" md="4">
            <v-card color="" class="px-3 py-2 default" outlined
              >What catering services are available for the venue?</v-card
            >
          </v-col>
        </v-row>

        <v-row class="my-0">
          <v-col
            cols="12"
            md="8"
            v-for="(cateringType, index) in form.catering"
            :key="index"
            class="equipment-height word-break"
          >
            <label
              :class="{ 'text--secondary': !form.catering[index].selected }"
              ><i :class="form.catering[index].icon + ' mr-3'"></i>
              {{ form.catering[index].name }}</label
            >
            <div
              v-if="form.catering[index].removable"
              class="float-right mt-1 mr-1 pointer"
              @click="removeCateringType(index)"
            >
              <i class="mdi mdi-close"></i>
            </div>
            <v-checkbox
              class="float-right mt-0"
              :class="{ 'mr-4': !form.catering[index].removable }"
              v-model="form.catering[index].selected"
              :key="index"
            ></v-checkbox>
          </v-col>
        </v-row>

        <v-row class="mt-8">
          <v-col cols="12" md="8">
            <h5>What other catering service do you have?</h5>
          </v-col>
          <v-col cols="12" md="4">
            <v-card class="px-3 py-2 default" outlined
              >Add new catering service and click <strong>+</strong> icon
              button</v-card
            >
          </v-col>
        </v-row>

        <v-row class="my-0">
          <v-col cols="12" md="8">
            <v-text-field
              label="Other service name"
              v-model="newCateringType"
              append-icon="mdi-plus"
              :rules="[getRules.string]"
              @keydown.enter.prevent="addCateringType"
              @click:append="addCateringType"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-form>

    <v-btn color="primary" @click="this.continue" class="float-right mt-5 mr-3"
      >Continue</v-btn
    >
    <v-btn
      color="secondary"
      class="float-right mt-5 mr-1"
      @click="submit('draft')"
      v-if="
        (getVendor && getVendor.status !== 'active') ||
        getIsVendorUpdate === false
      "
      >Save As Draft</v-btn
    >
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Catering",
  props: ["name", "index", "form"],
  components: {},
  data: () => ({
    humanResources: [
      "Waiters",
      "Show Organizers",
      "Host",
      "Cheerleaders",
      "Security",
      "Bouncers",
    ],
    furnitureItems: [
      "Carpets",
      "Meeting Rooms",
      "Laptops",
      "Chair & Table",
      "Exhibitions",
      "Main Gates",
      "Fire Alarms",
      "Emergency Exists",
    ],
    newFurniture: "",
    newCateringType: "",
    newHumanResource: "",
    isValid: true,
  }),
  created() {
    this.furnitureItems = _.union(this.furnitureItems, this.form.furniture);
    this.humanResources = _.union(
      this.humanResources,
      this.form.human_resources
    );
  },
  computed: {
    ...mapGetters(["getVendor", "getIsVendorUpdate", "getRules"]),
  },
  methods: {
    async submit(type = "draft") {
      this.$emit("submit", type);
    },
    continue() {
      this.$emit("locationNextStep", this.index);
    },
    addFurniture() {
      if (this.$refs.form.validate()) {
        if (this.newFurniture) {
          this.furnitureItems.push(this.newFurniture);
          this.newFurniture = "";
        }
      }
    },
    addHumanResource() {
      if (this.$refs.form.validate()) {
        if (this.newHumanResource) {
          this.humanResources.push(this.newHumanResource);
          this.newHumanResource = "";
        }
      }
    },
    addCateringType() {
      if (this.$refs.form.validate()) {
        if (this.newCateringType.replace(/ /g, "") !== "") {
          this.form.catering.push({
            name: this.newCateringType,
            icon: "mdi mdi-auto-fix",
            selected: false,
            removable: true,
          });
          this.newCateringType = "";
        }
      }
    },
    removeCateringType(removeIndex) {
      let cateringTypes = this.form.catering;
      this.form.catering = _.filter(cateringTypes, (cateringType, index) => {
        if (removeIndex !== index) {
          return cateringType;
        }
      });
    },
  },
};
</script>
<style scoped>
.custom-item-column {
  padding: 7px !important;
  flex-grow: 0 !important;
}
.bg-color-grey {
  background-color: #f9f9f9;
}

.color-grey {
  color: #f9f9f9;
}
.equipment-height {
  height: 35px;
}

.pointer {
  cursor: pointer;
}
span.v-chip.chip-active-style.v-chip--active {
  background: #000000 !important;
  color: #ffffff !important;
  border-radius: 5px !important;
  border: 1px solid #000000;
}
.chip-style {
  border: 1px solid #000000;
  border-radius: 5px !important;
}
.word-break {
  word-wrap: break-word;
}
</style>
